<template>
  <div id="userManagement">
    <a-page-header
      title="用户管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <div class="formStyle">
      <div>
        <span>姓名: </span>
        <a-input style="width: 150px" v-model="form.userName" placeholder="请输入" />
      </div>
      <div>
        <span>联系方式: </span>
        <a-input style="width: 150px" @input="handleInput" v-model="form.userPhone" placeholder="请输入" />
      </div>
      <div>
        <span>用户标签: </span>
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          mode="multiple"
          placeholder="请选择"
          option-filter-prop="children"
          :maxTagCount="1"
          :filter-option="filterOption"
          style="width: 210px"
          v-model="form.userLableInfo"
        >
          <a-select-option v-for="(item, index) in userType('用户标签')" :key="index" :value="item.labelId">
            {{ item.labelName }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>状态: </span>
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          placeholder="请选择"
          v-model="form.userStatus"
          style="width: 120px"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option value="0"> 启用 </a-select-option>
          <a-select-option value="1"> 禁用 </a-select-option>
        </a-select>
      </div>
      <div>
        <span class="btnClass">
          <a-button type="primary" @click="search">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
        </span>
      </div>
    </div>
    <div class="middle">
      <a-button
        type="primary"
        @click="showModal"
        v-if="$checkButtonPermissions($buttonPermissionsConfig.userManagement.userManage.add)"
      >
        <a-icon type="plus" />添加
      </a-button>
      <a-table
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        style="margin-top: 15px"
        :pagination="false"
        :columns="columns"
        :data-source="userInfoData"
        bordered
      >
        <template slot="userLableList" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ record.userLableList }}</span>
            </template>

            <div
              style="
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              "
            >
              {{ record.userLableList }}
            </div>
          </a-tooltip>
          <!-- <div v-show-tips style="display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden">
              {{ record.userLableList }}
            </div> -->
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="modify(record.id)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.userManagement.userManage.edit)"
            >编辑</a
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="deleteList(record.id)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.userManagement.userManage.delete)"
            >删除</a
          >
        </template>
      </a-table>
    </div>
    <div class="fotter">
      <a-pagination
        show-size-changer
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
        :pageSizeOptions="['20', '50', '100']"
        :defaultPageSize="pageSize"
        :defaultCurrent="pageNum"
        :current="pageNum"
        :showSizeChanger="true"
        :pageSize="pageSize"
        :total="total"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      pageSize: 20,
      pageNum: 1,
      isActive: false,
      form: {
        userStatus: "",
        userLableInfo: [],
      },
      columns: [
        {
          title: "ID",
          dataIndex: "userNumber",
          width: 100,
        },
        {
          title: "姓名",
          dataIndex: "userName",
          width: 100,
        },
        {
          title: "联系方式",
          dataIndex: "userPhone",
          width: 200,
        },
        {
          title: "用户标签",
          scopedSlots: { customRender: "userLableList" },
          width: 200,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreate",
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
          width: 200,
        },

        {
          title: "状态",
          dataIndex: "userStatus",
          width: 100,
        },
      ],
      // 操作集合
      actions: [
        this.$buttonPermissionsConfig.userManagement.userManage.edit,
        this.$buttonPermissionsConfig.userManagement.userManage.delete,
      ],
    };
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({ width: "150px" }));
    this.labelManagement({ isAll: 1 });
    this.getUserInfoFn({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.form });
  },
  mounted() {},
  computed: {
    ...mapState({
      dataList: (state) => state.label.dataArr,
      userInfoData: (state) => state.userManagement.userInfoData,
      total: (state) => state.userManagement.total,
    }),
  },

  methods: {
    ...mapActions({
      getUserInfoFn: "userManagement/getUserInfoFn",
      labelManagement: "label/labelManagement",
      userDeletedFn: "userManagement/userDeletedFn",
    }),

    handleInput(e) {
      this.form.userPhone = e.target.value.replace(/[^\d]/g, "");
    },

    //过滤标签
    userType(types) {
      let arr = this.dataList.filter((item) => {
        return item.labelType == types;
      });
      return arr;
    },
    //模糊查询根据当前得数据返回input中相匹配得值.
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },

    //编辑
    modify(record) {
      let id = JSON.stringify(record);
      this.$router.push({
        path: "/userManagement/addModify",
        query: { Disable: false, id, isActive: 2 },
      });
    },

    //添加
    showModal() {
      this.$router.push({ path: "/userManagement/addModify", query: { Disable: true, isActive: 1 } });
    },
    //删除
    deleteList(id) {
      this.$confirm({
        title: "确定要删除吗?",
        content: "删除后不可恢复",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.userDeletedFn({
            id,
            pageNum: 1,
            pageSize: this.pageSize,
            cb: () => {
              this.success("删除成功");
            },
            cbError: (res) => {
              this.warning(res);
            },
          });
        },
        onCancel: () => {
          // this.warning("已取消");
          return false;
        },
      });
    },
    //查询
    search() {
      // let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/; //正则校验手机号
      // if (this.form.userPhone && !phonereg.test(this.form.userPhone.trim())) {
      //   this.warning("手机号有误! 请从新输入");
      // } else {
      this.isActive = true;
      this.pageNum = 1;
      this.getUserInfoFn({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.form });
      // }
    },
    //重置
    resetSearchForm() {
      this.form = {
        userStatus: "",
        userLableInfo: [],
      };
      this.isActive = false;

      this.pageSize = 20;
      this.pageNum = 1;
      this.getUserInfoFn({ pageNum: 1, pageSize: 20 });
    },

    //分页
    onShowSizeChange(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      if (this.isActive) {
        this.getUserInfoFn({
          pageNum,
          pageSize,
          ...this.form,
        });
      } else {
        this.getUserInfoFn({ pageNum, pageSize });
      }
    },
    onChangePage(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      if (this.isActive) {
        this.getUserInfoFn({
          pageNum,
          pageSize,
          ...this.form,
        });
      } else {
        this.getUserInfoFn({ pageNum, pageSize });
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
}

.formStyle {
  display: flex;
  flex-wrap: wrap;
}
.formStyle > div {
  margin-left: 10px;
}
.header button {
  margin-left: 20px;
}

.middle {
  margin-top: 15px;
}

.fotter {
  float: right;
  padding: 20px 0;
}

::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}

.btnClass {
  margin-left: 10px;
}
</style>
